import { Step1Page } from './Step1'
import { CheckoutPage } from './Checkout'
import ThankUPage from './ThankU'
import { Route, Routes } from 'react-router-dom'
import React from 'react'
import { useMarketing } from '../../context/MarketingContext'

export const GBV1Routes = () => {
    const {setMarket} = useMarketing()
    setMarket("gb")
    return (
        <Routes>
           <React.Fragment key={"v2"}>
                        <Route path={`/`} element={<Step1Page />} />
                        <Route path={`/checkout`} element={<CheckoutPage />} />
                        <Route path={`/thank-you`} element={<ThankUPage />} />
            </React.Fragment>
        </Routes>
    )
}
