import { useLocation } from "react-router-dom";
import { CampaignsConfig } from "../constants/campaignsConfig";


export const useCampaignChecker = (cmpId?:string) => {

const location = useLocation();
const pathSegments = location.pathname.split('/').filter(Boolean);
const firstPathSegment = pathSegments[0]; 
console.log(firstPathSegment,"firstPathSegment")
let campaignId: string | undefined;
if(cmpId){
  campaignId = cmpId
}
else if (firstPathSegment in CampaignsConfig) {
  campaignId = CampaignsConfig[firstPathSegment as keyof typeof CampaignsConfig];
} else {
  campaignId = CampaignsConfig.default;
}
   
  
    return {campaignId};
  };
  